"use client";
import "src/index.css"

import {
  Box,
  HStack,
  useColorModeValue,
  Center,
  Image,
  Flex,
  Button,
} from "@chakra-ui/react";
import { NavLink } from "src/components/mobile-nav";
import LayoutDefault from "src/layouts/LayoutDefault";
import React from "react";
import { getAccessToken } from "src/authUtils";
import LocalePicker from "src/components/locale-picker";
import Link from "src/renderer/link";
import T from "src/renderer/local-text";


const LINKS: Array<LinkItemProps> = [
  // { name: "Use Cases", href: "#use-cases" },
  // { name: "Demo", href: "/#demo" },
  { name: "Use cases", href: "/#use-cases" },
  // { name: "Templates", href: "/#templates" },
  { name: "Simulator", href: "/#cost-simulator" },
  { name: "Integrations", href: "/#integrations" },
  { name: "Pricing", href: "/#pricing" },
  // { name: "Gallery", href: "/gallery" },
  { name: "FAQ", href: "/#faq" },
];


const LayoutLanding = ({ children }) => {
  const navbarHeightBase = '88px';
  const navbarHeightLg = '88px';
  const navbarHeight = { base: navbarHeightBase, lg: navbarHeightLg }
  const [loginToken, setLoginToken] = React.useState('unknown');
  const [scrollAlpha, setScrollAlpha] = React.useState(0);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoginToken(getAccessToken())
    }
  }, []);

  const bg = useColorModeValue(
    `rgba(255, 255, 255, ${scrollAlpha})`, // Light mode: white background
    `rgba(26, 32, 44, ${scrollAlpha})`    // Dark mode: gray.900 background
  );
  const borderColor = useColorModeValue(
    `rgba(226, 232, 240, ${scrollAlpha})`, // Light mode: gray.200
    `rgba(74, 85, 104, ${scrollAlpha})`    // Dark mode: gray.700
  );

  React.useEffect(() => {
    const handleScroll = () => {
      const maxScroll = 200; // Adjust this value for when the background is fully opaque
      const scrollTop = window.scrollY;
      const alpha = Math.min(scrollTop / maxScroll, 1); // Clamp alpha between 0 and 1
      setScrollAlpha(alpha);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <LayoutDefault>
      <Box as={"header"} position={"fixed"} top={0} w="100%" zIndex={1000}>
        <Flex
          px={{ base: 2, md: 16 }}
          justify="space-between"
          align="center"
          bg={bg}
          h={navbarHeight}
          borderBottomColor={borderColor}
          borderBottomWidth='2px'
        >
          <Box >
            <Link href='/'>
              <Image h='30px' src='/logo-black.svg' alt='Plurally logo' />
            </Link>
          </Box>
          <HStack
            as={"nav"}
            spacing={4}
            display={{ base: "none", xl: "inherit" }}
          >
            {LINKS.map((link) => (
              <NavLink
                href={link.href}
                key={`top-${link.name}`}
                isExternal={link.isExternal}
              >
                <T>
                  {link.name}
                </T>
              </NavLink>
            ))}
          </HStack>
          <HStack spacing={4}>
            <Box display='none'>
              {loginToken === 'unknown' ? (
                <Button variant="link" me="2" as={Link} href="/auth" visibility='hidden'>Sign in</Button>
              ) : loginToken ? (
                <Button colorScheme="brand" me="2" as={Link} href="/home" size='sm'>
                  Dashboard
                </Button>
              ) : (
                <Button variant="link" me="2" as={Link} href="/auth">Sign in</Button>
              )}
            </Box>
            <Button variant="link" me="2" as={Link} href="/auth"><T>Sign in</T></Button>
            <Box>
              <LocalePicker />
            </Box>
          </HStack>
        </Flex>
      </Box>
      <Box
        as={"main"}
        // mt={navbarHeight}
        // minH={`calc(100vh - ${navbarHeight})`}
        minH='calc(100vh)'
        w="100%"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
      <Box bg={bg}>
        <Box py={12}>
          <Center>
            <HStack spacing={4}>
              {/* <Link href="/blog">Blog</Link> */}
              <Link href="/privacy"><T>Privacy Policy</T></Link>
            </HStack>
          </Center>
        </Box>
      </Box>
    </LayoutDefault>
  );
};

export default LayoutLanding;
